import React from 'react';
import {Disclosure} from '@headlessui/react';
import cx from 'classnames';

import Link from '../../components/Link';
import {CaretIcon} from '../../components/svg';
import QuickActions from '../../constants/actions';
import {shouldHideCoaching} from '../../util';
import {useAppContext, useAnalytics, useWindowSize} from '../../hooks';

const colors = [
  {bg: 'bg-blue-100', text: 'text-blue-700'},
  {bg: 'bg-indigo-100', text: 'text-indigo-700'},
  {bg: 'bg-purple-100', text: 'text-purple-700'},
  {bg: 'bg-green-100', text: 'text-green-700'},
  {bg: 'bg-yellow-100', text: 'text-yellow-700'},
  {bg: 'bg-pink-100', text: 'text-pink-700'},
  {bg: 'bg-red-100', text: 'text-red-700'},
];

const getColCount = (width) => {
  if (width >= 1280) return 7; // xl
  if (width >= 1024) return 6; // lg
  if (width >= 768) return 5; // md
  return 0;
};

const ActionBar = () => {
  const {currentUser} = useAppContext();
  const {tracker} = useAnalytics();
  const [role, roleCategory] = currentUser?.profile?.career_tracks || [];
  const {width} = useWindowSize();
  const visibleCount = getColCount(width);

  let actions = QuickActions[role] || QuickActions[roleCategory] || QuickActions.default;
  if (shouldHideCoaching(currentUser)) {
    actions = actions.filter((action) => !action.addOn);
  }

  return (
    <>
      {/* Desktop view */}
      <div className="hidden mx-3 mb-6 md:grid gap-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7">
        {actions.slice(0, visibleCount).map((action, index) => (
          <Link
            href={action.link}
            className="flex flex-col items-start p-4 rounded-lg shadow bg-white hover:bg-gray-50 transition-all"
            onClick={() => tracker.dashboardClickActionBar({link: action.link})}
          >
            <div className={cx('p-2 rounded-md', colors[index % colors.length].bg)}>
              <action.icon className={cx('h-5 w-5', colors[index % colors.length].text)} />
            </div>
            <div className="mt-2 font-medium text-gray-700">
              {action.action}
            </div>
          </Link>
        ))}
      </div>

      {/* Mobile view */}
      <Disclosure as="div" className="md:hidden w-full mx-3 mb-6 rounded-lg bg-white shadow">
        {({open}) => (
          <>
            <Disclosure.Button className="w-full">
              <div className="flex items-center justify-between py-4 px-6">
                <span className="font-semibold">Quick actions</span>
                <CaretIcon
                  width={9}
                  height={12}
                  className={cx('transform -rotate-180 transition duration-200', {'-rotate-90': open})}
                  fill="currentColor"
                />
              </div>
            </Disclosure.Button>

            <Disclosure.Panel className="flex flex-col w-full px-2 -mt-2 pb-2">
              {actions.map((action, index) => (
                <Link
                  href={action.link}
                  className="flex items-center space-x-3 p-3"
                  onClick={() => tracker.dashboardClickActionBar({link: action.link})}
                >
                  <div className={cx('p-2 rounded-full', colors[index % colors.length].bg)}>
                    <action.icon className={cx('h-5 w-5', colors[index % colors.length].text)} />
                  </div>
                  <div className="text-gray-700 font-medium">{action.action}</div>
                </Link>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default ActionBar;
