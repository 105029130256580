import {
  BriefcaseIcon,
  ChatAlt2Icon,
  CodeIcon,
  NewspaperIcon,
  PresentationChartBarIcon,
  AcademicCapIcon,
  UserGroupIcon,
  DocumentTextIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';

/**
 * Actions are displayed according to user's role.
 * If no match, according to role-category.
 * If still no match, default actions are displayed.
 *
 * Each role can render up to 7 actions on desktop and no cap on mobile.
 * Before shipping any new actions, ensure 'action' text still looks good on desktop and mobile.
 */
const QuickActions = {
  // ROLES

  // Product Manager
  pm: [{
    action: 'Learn to ace PM interviews',
    link: '/courses/pm?src=action-bar',
    icon: AcademicCapIcon,
  }, {
    action: 'View interview questions',
    link: '/questions?role=pm&src=action-bar',
    icon: QuestionMarkCircleIcon,
  }, {
    action: 'Practice with an AI interviewer',
    link: '/practice?src=action-bar',
    icon: ChatAlt2Icon,
  }, {
    action: 'Practice with mock interviews',
    link: '/practice?src=action-bar',
    icon: UserGroupIcon,
  }, {
    action: 'Practice with a PM coach',
    link: '/coaching?category=mock_interviews&role=pm&src=action-bar',
    addOn: true,
    icon: PresentationChartBarIcon,
  }, {
    action: 'Study PM interview loops',
    link: '/guides?role=pm&src=action-bar',
    icon: NewspaperIcon,
  }, {
    action: 'Get referred to a company',
    link: '/jobs/referrals?src=action-bar',
    icon: BriefcaseIcon,
  }, {
    action: 'Review your resume',
    link: '/resume?src=action-bar',
    addOn: true,
    icon: DocumentTextIcon,
  }],

  // Software Engineer
  swe: [{
    action: 'Learn to ace SWE interviews',
    link: '/courses/software-engineering?src=action-bar',
    icon: AcademicCapIcon,
  }, {
    action: 'Practice coding questions',
    link: '/courses/software-engineering?src=action-bar#swe-practice',
    icon: CodeIcon,
  }, {
    action: 'View interview questions',
    link: '/questions?role=swe&src=action-bar',
    icon: QuestionMarkCircleIcon,
  }, {
    action: 'Practice with an AI interviewer',
    link: '/practice?src=action-bar',
    icon: ChatAlt2Icon,
  }, {
    action: 'Practice with mock interviews',
    link: '/practice?src=action-bar',
    icon: UserGroupIcon,
  }, {
    action: 'Practice with a SWE coach',
    link: '/coaching?category=mock_interviews&role=swe&src=action-bar',
    addOn: true,
    icon: PresentationChartBarIcon,
  }, {
    action: 'Study SWE interview loops',
    link: '/guides?role=swe&src=action-bar',
    icon: NewspaperIcon,
  }, {
    action: 'Get referred to a company',
    link: '/jobs/referrals?src=action-bar',
    icon: BriefcaseIcon,
  }, {
    action: 'Review your resume',
    link: '/resume?src=action-bar',
    addOn: true,
    icon: DocumentTextIcon,
  }],

  // Engineering Manager
  em: [{
    action: 'Learn to ace EM interviews',
    link: '/courses/engineering-management?src=action-bar',
    icon: AcademicCapIcon,
  }, {
    action: 'Practice with an AI interviewer',
    link: '/practice?src=action-bar',
    icon: ChatAlt2Icon,
  }, {
    action: 'Practice with mock interviews',
    link: '/practice?src=action-bar',
    icon: UserGroupIcon,
  }, {
    action: 'Brush up on coding',
    link: '/courses/swe-practice?src=action-bar',
    icon: CodeIcon,
  }, {
    action: 'Study EM interview loops',
    link: '/guides?role=em&src=action-bar',
    icon: NewspaperIcon,
  }, {
    action: 'Practice with an EM coach',
    link: '/coaching?category=mock_interviews&role=em&src=action-bar',
    addOn: true,
    icon: PresentationChartBarIcon,
  }, {
    action: 'Get referred to a company',
    link: '/jobs/referrals?src=action-bar',
    icon: BriefcaseIcon,
  }, {
    action: 'Review your resume',
    link: '/resume?src=action-bar',
    addOn: true,
    icon: DocumentTextIcon,
  }],

  // Technical Program Manager
  tpm: [{
    action: 'Learn to ace TPM interviews',
    link: '/courses/tpm?src=action-bar',
    icon: AcademicCapIcon,
  }, {
    action: 'View interview questions',
    link: '/questions?role=tpm&src=action-bar',
    icon: QuestionMarkCircleIcon,
  }, {
    action: 'Practice with an AI interviewer',
    link: '/practice?src=action-bar',
    icon: ChatAlt2Icon,
  }, {
    action: 'Practice with mock interviews',
    link: '/practice?src=action-bar',
    icon: UserGroupIcon,
  }, {
    action: 'Practice with a TPM coach',
    link: '/coaching?category=mock_interviews&role=tpm&src=action-bar',
    addOn: true,
    icon: PresentationChartBarIcon,
  }, {
    action: 'Get referred to a company',
    link: '/jobs/referrals?src=action-bar',
    icon: BriefcaseIcon,
  }, {
    action: 'Review your resume',
    link: '/resume?src=action-bar',
    addOn: true,
    icon: DocumentTextIcon,
  }],

  // Solutions Architect
  'solutions-architect': [{
    action: 'Learn to ace your interviews',
    link: '/courses/solution-architect-interview?src=action-bar',
    icon: AcademicCapIcon,
  }, {
    action: 'View interview questions',
    link: '/questions?role=solutions-architect&src=action-bar',
    icon: QuestionMarkCircleIcon,
  }, {
    action: 'Practice with an AI interviewer',
    link: '/practice?src=action-bar',
    icon: ChatAlt2Icon,
  }, {
    action: 'Practice with mock interviews',
    link: '/practice?src=action-bar',
    icon: UserGroupIcon,
  }, {
    action: 'Practice with an expert coach',
    link: '/coaching?category=mock_interviews&role=solutions-architect&src=action-bar',
    addOn: true,
    icon: PresentationChartBarIcon,
  }, {
    action: 'Get referred to a company',
    link: '/jobs/referrals?src=action-bar',
    icon: BriefcaseIcon,
  }, {
    action: 'Review your resume',
    link: '/resume?src=action-bar',
    addOn: true,
    icon: DocumentTextIcon,
  }],

  // ML Engineer
  'ml-engineer': [{
    action: 'Learn to ace ML interviews',
    link: '/courses/ml-engineer?src=action-bar',
    icon: AcademicCapIcon,
  }, {
    action: 'Practice ML coding',
    link: '/courses/ml-engineer?src=action-bar#ml-coding',
    icon: CodeIcon,
  }, {
    action: 'Practice DSA coding',
    link: '/courses/ml-engineer?src=action-bar#swe-practice',
    icon: CodeIcon,
  }, {
    action: 'Practice with an AI interviewer',
    link: '/practice?src=action-bar',
    icon: ChatAlt2Icon,
  }, {
    action: 'Practice with mock interviews',
    link: '/practice?src=action-bar',
    icon: UserGroupIcon,
  }, {
    action: 'Practice with an ML coach',
    link: '/coaching?category=mock_interviews&role=ml-engineer&src=action-bar',
    addOn: true,
    icon: PresentationChartBarIcon,
  }, {
    action: 'Study ML interview loops',
    link: '/guides?role=ml-engineer&src=action-bar',
    icon: NewspaperIcon,
  }, {
    action: 'Get referred to a company',
    link: '/jobs/referrals?src=action-bar',
    icon: BriefcaseIcon,
  }, {
    action: 'Review your resume',
    link: '/resume?src=action-bar',
    addOn: true,
    icon: DocumentTextIcon,
  }],

  // Data Scientist
  'data-science': [{
    action: 'Learn to ace DS interviews',
    link: '/courses/data-science?src=action-bar',
    icon: AcademicCapIcon,
  }, {
    action: 'Practice Python coding',
    link: '/courses/data-science?src=action-bar#python-coding-data-scientist-questions',
    icon: CodeIcon,
  }, {
    action: 'Practice SQL coding',
    link: '/courses/data-science?src=action-bar#sql-interviews',
    icon: CodeIcon,
  }, {
    action: 'Practice with an AI interviewer',
    link: '/practice?src=action-bar',
    icon: ChatAlt2Icon,
  }, {
    action: 'Do a SQL mock interview',
    link: '/practice?src=action-bar',
    icon: UserGroupIcon,
  }, {
    action: 'Practice with a DS coach',
    link: '/coaching?category=mock_interviews&role=data-science&src=action-bar',
    addOn: true,
    icon: PresentationChartBarIcon,
  }, {
    action: 'Study DS interview loops',
    link: '/guides?role=data-science&src=action-bar',
    icon: NewspaperIcon,
  }, {
    action: 'Get referred to a company',
    link: '/jobs/referrals?src=action-bar',
    icon: BriefcaseIcon,
  }, {
    action: 'Review your resume',
    link: '/resume?src=action-bar',
    addOn: true,
    icon: DocumentTextIcon,
  }],

  // Data Engineer
  'data-engineer': [{
    action: 'Learn to ace DE interviews',
    link: '/courses/data-engineering?src=action-bar',
    icon: AcademicCapIcon,
  }, {
    action: 'Practice SQL questions',
    link: '/courses/data-engineering?src=action-bar#sql-interviews',
    icon: CodeIcon,
  }, {
    action: 'Practice with an AI interviewer',
    link: '/practice?src=action-bar',
    icon: ChatAlt2Icon,
  }, {
    action: 'Do a SQL mock interview',
    link: '/practice?src=action-bar',
    icon: UserGroupIcon,
  }, {
    action: 'Practice with a DE coach',
    link: '/coaching?category=mock_interviews&role=data-engineer&src=action-bar',
    addOn: true,
    icon: PresentationChartBarIcon,
  }, {
    action: 'Study DE interview loops',
    link: '/guides?role=data-engineer&src=action-bar',
    icon: NewspaperIcon,
  }, {
    action: 'Get referred to a company',
    link: '/jobs/referrals?src=action-bar',
    icon: BriefcaseIcon,
  }, {
    action: 'Review your resume',
    link: '/resume?src=action-bar',
    addOn: true,
    icon: DocumentTextIcon,
  }],

  // ROLE CATEGORIES


  // DEFAULT
  default: [{
    action: 'Start a course',
    link: '/courses?src=action-bar',
    icon: AcademicCapIcon,
  }, {
    action: 'View interview questions',
    link: '/questions?src=action-bar',
    icon: QuestionMarkCircleIcon,
  }, {
    action: 'Practice with an AI interviewer',
    link: '/practice?src=action-bar',
    icon: ChatAlt2Icon,
  }, {
    action: 'Practice with mock interviews',
    link: '/practice?src=action-bar',
    icon: UserGroupIcon,
  }, {
    action: 'Study company interview loops',
    link: '/guides?src=action-bar',
    icon: NewspaperIcon,
  }, {
    action: 'Practice with an expert coach',
    link: '/coaching?category=mock_interviews&src=action-bar',
    addOn: true,
    icon: PresentationChartBarIcon,
  }, {
    action: 'Get referred to a company',
    link: '/jobs/referrals?src=action-bar',
    icon: BriefcaseIcon,
  }, {
    action: 'Review your resume',
    link: '/resume?src=action-bar',
    addOn: true,
    icon: DocumentTextIcon,
  }],
};

export default QuickActions;
